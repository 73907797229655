<template>
  <div id="app">

    <div v-if="step==1">
      <!-- 第一步 -->
      <div class="bg_green">
        <div class="white_box3">
          <!-- 标题 -->
          <div class="orange_title" style="font-weight: normal;">1.请选择提现项目</div>
          <div class="white_box3_in">
            <!-- 副标题 -->
            <div class="sub_title">请选择</div>
            <van-checkbox-group v-model="checkIds" @change="changeCheckbox">
              <!-- 用户 -->
              <div class="user under_line pd_3p6_0 clear" v-for="(option,index) in userPocket"
                   :key="index"
                   v-if="option.balance>0">
                <div class="user_msg">
                  <span>{{
                      option.type === 'FREEDOM' ? '零钱' : (option.productBundle ? option.productBundle.name : '-')
                    }}</span>
                </div>
                <div class="user_r2">
                  {{
                    onlyUnFrozenFreedom.setValue === '1' &&
                    option.type === 'PRODUCT_BUNDLE' ? '暂不可提现' : option.balance.toFixed(2)
                  }}
                  <van-checkbox shape="square" checked-color="#34AC40" :name="option.id"
                                icon-size="12px"
                                v-if="onlyUnFrozenFreedom.setValue==='0' || (onlyUnFrozenFreedom.setValue==='1' && option.type!=='PRODUCT_BUNDLE')"></van-checkbox>
                </div>
              </div>
            </van-checkbox-group>

            <!-- 统计 -->
            <div class="total_count">可提现：{{ totalAmount.toFixed(2) }}元</div>

          </div>

        </div>
      </div>

      <!-- 第二步 -->
      <div class="white_box3 mgt_3vw">
        <!-- 标题 -->
        <div class="dark_title" style="font-weight: normal;">
          <span>2.请确认提现金额</span>
          <label @click="checkAll">全额提现</label>
        </div>
        <!-- 金额+选择帐号 -->
        <div class="amount">￥ {{ totalAmount.toFixed(2) }}</div>
        <div class="txb border_none">
          <div style="display: flex; margin: 12px 16px 0 16px;">
            <div style="flex: 1;">
              {{ '收款帐号' }}
            </div>
            <div>
              <div>{{ bindingCardNo }}</div>
              <div style="font-size: 13px; float: right; margin-top: 4px; color: #999999;">{{ bindingCardBankName }}</div>
            </div>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="two_btn">
          <ul class="clear">
            <li>
              <button class="button_dark" @click="$router.push({path: 'customer_user_account'})">
                取消
              </button>
            </li>
            <li>
              <button class="button_green" @click="next">提交</button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <div v-if="step==2">
      <div>
        <div class="bank3_title">身份验证</div>
        <div class="bank3_sub_title">请验证支付密码确认本人操作</div>
        <div class="yzm2">
          <van-password-input
              :value="password"
              :gutter="10"
              :focused="showPasswordKeyboard"
              @focus="showPasswordKeyboard = true"
          />
        </div>
        <div style="text-align: center; padding-top: 20px;">
          <div
              style="position: absolute; padding-top: 28px; color: rgb(28, 122, 235); margin-right: 24px; right: 0; font-size: 12px;"
              @click="$router.push({path: '/verification'})">
            忘记支付密码?
          </div>
          <a-button type="link" @click="step=1" class='cancel_recharge'>
            取消
          </a-button>
        </div>
        <van-number-keyboard
            v-model="password"
            :maxlength="6"
            :show="showPasswordKeyboard"
            @blur="showPasswordKeyboard = false"
        />
      </div>
    </div>

  </div>
</template>

<style scoped>
::v-deep .van-checkbox {
  margin-left: 10px;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {Dialog} from 'vant';
import Notify from "vant/es/notify";
import {guid} from "../utils/util";
import {toDecimal} from "../utils/decimals";

export default {
  data() {
    return {
      step: 1,
      password: "",
      showPasswordKeyboard: true,

      loading: false,
      checkIds: [],
      bindingCardNo: '',
      bindingCardBankName: '',
      totalAmount: 0,
      customerUser: {},
      customerAccount: {},
      userPocket: [],
      onlyUnFrozenFreedom: {},

      // 记录提现的餐费包是什么渠道的
      paymentPlatformId: '',

      deposit: {},
      sign: "",
    };
  },
  watch: {
    password(val) {
      if (val.length === 6) {
        this.confirmWithdrawDepositAndWithdraw();
      }
    }
  },
  mounted() {
    this.setupData();
  },
  methods: {
    setupData() {
      this.step = 1;

      this.checkIds = [];
      this.deposit = {};
      this.customerUser = Vue.ls.get(ACCESS_CustomerUser)
      this.loadUserPocket();
      this.sign = guid();

      post('api/customer/account/v2/own/read/cardNo', {}).then(res => {
        if (res.data) {
          this.bindingCardNo = res.data.bindingCardNo;
          this.bindingCardBankName = res.data.bindingCardBankName;
        }
      })
    },
    loadSetting() {
      post('api/business/v2/{businessId}/setting/search'.replace('{businessId}', this.customerUser.businessId), {
        size: 500
      }).then(res => {
        this.onlyUnFrozenFreedom = res.data.rows.find(item => item.setKey === 'ONLY_UNFROZEN_FREEDOM'); // 仅解冻自由充值金额
        if (!this.onlyUnFrozenFreedom) {
          this.onlyUnFrozenFreedom = {setValue: '0'};// 默认为否
        }
      }).catch(() => {
      });
    },
    loadUserPocket() {
      if (!this.customerUser) return;
      this.loading = true;

      post('api/user/pocket/v2/:businessCustomerUserId/findByThaw'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}).then(res => {
        this.userPocket = res.data;

        if (this.userPocket && this.userPocket.length > 0) {
          this.userPocket.forEach(item => {
            this.paymentPlatformId = item.paymentPlatformId;
          })
        } else {
          // this.showAccountErrDialog("-1");
          Dialog.alert({message: "您没有可提现的金额"}).then(() => {
            this.$router.push({path: 'customer_user_account'});
          });
        }

        post('api/customer/account/v2/:businessCustomerUserId/find'.replace(':businessCustomerUserId', this.customerUser.businessCustomerUserId), {}, true).then(res => {
          this.customerAccount = res.data
          if (!this.customerAccount || !this.customerAccount.id) {
            this.showAccountErrDialog("-2");
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.showAccountErrDialog("-3");
        });

        this.loadSetting();
      }).catch(error => {
        Dialog.alert({message: error.message}).then(() => {
          this.$router.push({path: 'customer_user_account'})
        });
        this.loading = false;
      });
    },
    // 账户异常提示
    showAccountErrDialog(code) {
      Dialog.alert({message: "账户异常(" + code + ")，请联系客户处理，谢谢!"}).then(() => {
        this.$router.push({path: 'customer_user_account'})
      });
    },
    changeCheckbox() {
      this.totalAmount = 0;
      this.checkIds.forEach(item => {
        let pocket = this.userPocket.find(el => el.id === item)
        this.totalAmount += pocket.balance
      })
    },
    next() {
      if (!this.customerUser) return;
      if (!this.customerAccount || !this.customerAccount.id) return;

      if (this.totalAmount <= 0 || this.checkIds.length == 0) {
        Notify({type: 'warning', message: '请选择您要提现的数据项'});
        return;
      }

      const totalAmount = toDecimal(this.totalAmount)

      Dialog.confirm({
        message: '您确定要提现吗？'
      }).then(() => {
        this.loading = true
        post('api/pay/deposit/withdraw/create', {
          customerAccountId: this.customerAccount.id,
          totalAmount: totalAmount,
          businessCustomerUserId: this.customerUser.businessCustomerUserId,
          userPocketIds: this.checkIds,
          sign: this.sign
        }).then(result => {
          this.deposit = result.data;
          if (this.deposit) {
            if (this.deposit.needPassword === '1') {
              this.loading = false;
              this.step = 2;

            } else {
              // 直接确认
              this.confirmWithdrawDepositAndWithdraw();
            }

          }
        }).catch(error => {
          Notify({type: 'warning', message: error.message});
          this.loading = false
        });
      });
    },
    checkAll() {
      this.checkIds = []
      if (this.userPocket && this.userPocket.length > 0) {
        this.userPocket.forEach(item => {
          if (this.onlyUnFrozenFreedom.setValue === '0') {
            if (item.balance > 0) {
              this.checkIds.push(item.id)
            }
          } else {
            if (item.balance > 0 && item.type === 'FREEDOM') {
              this.checkIds.push(item.id)
            }
          }
        })
      }
    },
    // 2.确认
    confirmWithdrawDepositAndWithdraw() {
      if (!this.deposit) {
        if (this.step === 2) {
          Notify({type: "warning", message: "提现异常，请重新提现"});
          this.step = 1;

        } else {
          Notify({type: "warning", message: "提现异常，请重新提现（-1）"});

        }
        return;
      }

      this.loading = true;

      post('api/pay/deposit/withdraw/confirm', {
        depositId: this.deposit.depositId,
        password: this.password
      }).then(result => {
        // 广发钱包支付
        Dialog.alert({message: '提现成功'}).then(() => {
          this.setupData();
        });

        this.loading = false

      }).catch(error => {
        Dialog.alert({title: '提现失败', message: error.message}).then(() => {
          this.setupData();
        });
        this.loading = false
      });

    },
  }
};
</script>
